import type {LayerProps} from 'react-map-gl'

import type {ExpressionSpecification} from 'mapbox-gl'

import type {Filter} from 'src/components/copilot/AdvancedMapFilters'

export type LatLong = {
  lat: number
  long: number
}

export type BoundingBox = {
  topRight: LatLong
  bottomLeft: LatLong
}

export type SourceRequestPayload = {filter: Filter & {boundingBox: BoundingBox}}

const FIVE_MILES = 0.072
export const PLAIN_PILL_COLOR = '#8D8FA0'

function boundsToBox(
  box: Exclude<ReturnType<mapboxgl.Map['getBounds']>, null>,
): BoundingBox {
  const bottomLeft = box.getSouthWest()
  const topRight = box.getNorthEast()

  // TODO: this only works for lattitudes near Raleigh
  return {
    topRight: {
      lat: topRight.lat + FIVE_MILES,
      long: topRight.lng + FIVE_MILES,
    },
    bottomLeft: {
      lat: bottomLeft.lat - FIVE_MILES,
      long: bottomLeft.lng - FIVE_MILES,
    },
  }
}

export function getSourcePayload(
  box: Exclude<ReturnType<mapboxgl.Map['getBounds']>, null>,
  filter: Filter,
): SourceRequestPayload {
  const boundingBox = boundsToBox(box)
  return {
    filter: {
      ...filter,
      boundingBox,
    },
  }
}

export function payloadToSourceId(payload: SourceRequestPayload): string {
  return JSON.stringify(payload)
}

export function sourceIdToPayload(sourceId: string): SourceRequestPayload {
  return JSON.parse(sourceId) as SourceRequestPayload
}

export function clusterLayer(sourceId: string, color: string): LayerProps {
  return {
    id: `${sourceId}-clusters`,
    type: 'circle' as const,
    source: sourceId,
    filter: ['has', 'point_count'] as ExpressionSpecification,
    paint: {
      'circle-color': '#FFFFFF',
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        20,
        100,
        30,
        750,
        40,
      ] as ExpressionSpecification,
      'circle-stroke-color': color,
      'circle-stroke-width': 1,
      'circle-opacity': 1,
      'circle-stroke-opacity': 1,
    },
  }
}

export function countsLayer(sourceId: string, color: string): LayerProps {
  return {
    id: `${sourceId}-counts`,
    type: 'symbol' as const,
    source: sourceId,
    filter: ['has', 'point_count'] as ExpressionSpecification,
    layout: {
      'text-field': [
        'get',
        'point_count_abbreviated',
      ] as ExpressionSpecification,
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
    paint: {
      'text-color': color,
      'text-opacity': 1,
    },
  }
}

export function pillLayout(pillName: string) {
  return {
    // Icon (pill) settings
    'icon-image': pillName,
    'icon-size': 1,
    'icon-allow-overlap': true,
    'icon-text-fit': 'both' as const, // Make text fit within icon bounds
    'icon-text-fit-padding': [5, 10, 5, 10], // Add some padding inside the pill
    'icon-anchor': 'center' as const,
    'icon-ignore-placement': true, // Prevent icons from being hidden

    // Text settings
    'text-field': [
      'case',
      ['>=', ['to-number', ['get', 'salesPrice']], 1000000],
      [
        'concat',
        '$',
        [
          'number-format',
          ['/', ['round', ['to-number', ['get', 'salesPrice']]], 1000000],
          {
            'min-fraction-digits': 0,
            'max-fraction-digits': 1,
          },
        ],
        'M',
      ],
      ['>=', ['to-number', ['get', 'salesPrice']], 1000],
      [
        'concat',
        '$',
        [
          'number-format',
          [
            '/',
            [
              '*',
              ['round', ['/', ['to-number', ['get', 'salesPrice']], 1000]],
              1000,
            ],
            1000,
          ],
          {
            'min-fraction-digits': 0,
            'max-fraction-digits': 0,
          },
        ],
        'K',
      ],
      [
        'concat',
        '$',
        [
          'number-format',
          ['round', ['to-number', ['get', 'salesPrice']]],
          {
            'min-fraction-digits': 0,
            'max-fraction-digits': 0,
          },
        ],
      ],
    ] as ExpressionSpecification,
    'text-size': 11,
    'text-justify': 'center' as const,
    'text-anchor': 'center' as const,
    'text-allow-overlap': false,
    'text-ignore-placement': false,
    'text-optional': false,
    'text-padding': 0,

    // Symbol placement settings
    'symbol-placement': 'point' as const,
    'symbol-sort-key': ['get', 'salesPrice'] as ExpressionSpecification, // Higher prices on top
    'symbol-z-order': 'source' as const,
  }
}

export function castleLayout(castleName: string) {
  return {
    'icon-image': castleName,
    'icon-size': 1,
    'icon-allow-overlap': true,
    'icon-text-fit': 'width' as const,
    'icon-text-fit-padding': [5, 10, 5, 10], // Add some padding inside the pill
    'icon-anchor': 'center' as const,
    'icon-ignore-placement': true, // Prevent icons from being hidden

    // Text settings
    'text-field': [
      'case',
      ['>=', ['to-number', ['get', 'salesPrice']], 1000000],
      [
        'concat',
        '$',
        [
          'number-format',
          ['/', ['round', ['to-number', ['get', 'salesPrice']]], 1000000],
          {
            'min-fraction-digits': 0,
            'max-fraction-digits': 1,
          },
        ],
        'M',
      ],
      ['>=', ['to-number', ['get', 'salesPrice']], 1000],
      [
        'concat',
        '$',
        [
          'number-format',
          [
            '/',
            [
              '*',
              ['round', ['/', ['to-number', ['get', 'salesPrice']], 1000]],
              1000,
            ],
            1000,
          ],
          {
            'min-fraction-digits': 0,
            'max-fraction-digits': 0,
          },
        ],
        'K',
      ],
      [
        'concat',
        '$',
        [
          'number-format',
          ['round', ['to-number', ['get', 'salesPrice']]],
          {
            'min-fraction-digits': 0,
            'max-fraction-digits': 0,
          },
        ],
      ],
    ] as ExpressionSpecification,
    'text-size': 11,
    'text-justify': 'center' as const,
    'text-anchor': 'center' as const,
    'text-allow-overlap': false,
    'text-ignore-placement': false,
    'text-optional': false,
    'text-padding': 0,

    // Symbol placement settings
    'symbol-placement': 'point' as const,
    'symbol-sort-key': ['get', 'salesPrice'] as ExpressionSpecification, // Higher prices on top
    'symbol-z-order': 'source' as const,
  }
}

export function unclusteredLayer(
  sourceId: string,
  textColor: string,
  ignoreIds: string[],
): LayerProps {
  return {
    id: `${sourceId}-unclustered`,
    type: 'symbol' as const,
    source: sourceId,
    filter: [
      'all',
      ['!', ['has', 'point_count']],
      ['!', ['in', ['get', 'taxAssessorId'], ['literal', ignoreIds]]],
    ] as ExpressionSpecification,
    layout: pillLayout('plain-pill'),
    paint: {
      'text-color': textColor,
    },
  }
}

export function targetsLayer(
  sourceId: string,
  textColor: string,
  targetIds: string[],
): LayerProps {
  return {
    id: `${sourceId}-targets-unclustered`,
    type: 'symbol' as const,
    source: sourceId,
    filter: [
      'all',
      ['!', ['has', 'point_count']],
      ['in', ['get', 'taxAssessorId'], ['literal', targetIds]],
    ] as ExpressionSpecification,
    layout: pillLayout('target-pill'),
    paint: {
      'text-color': textColor,
    },
  }
}

export function portfolioLayer(
  sourceId: string,
  textColor: string,
  portfolioIds: string[],
): LayerProps {
  return {
    id: `${sourceId}-portfolio-unclustered`,
    type: 'symbol' as const,
    source: sourceId,
    filter: [
      'all',
      ['!', ['has', 'point_count']],
      ['in', ['get', 'taxAssessorId'], ['literal', portfolioIds]],
    ] as ExpressionSpecification,
    layout: pillLayout('portfolio-pill'),
    paint: {
      'text-color': textColor,
    },
  }
}

export function claimedLayer(
  sourceId: string,
  textColor: string,
  claimedIds: string[],
) {
  return {
    id: `${sourceId}-claimed-unclustered`,
    type: 'symbol' as const,
    source: sourceId,
    filter: [
      'all',
      ['!', ['has', 'point_count']],
      ['in', ['get', 'taxAssessorId'], ['literal', claimedIds]],
    ] as ExpressionSpecification,
    layout: castleLayout('claimed-castle'),
    paint: {
      'text-color': textColor,
    },
  }
}
