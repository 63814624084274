import React from 'react'
import Carousel from 'react-material-ui-carousel'

import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton, Modal, Stack} from '@mui/material'
import {grey} from '@mui/material/colors'

import {Parcel} from 'src/types/copilot'

interface Props {
  open: boolean
  onClose: () => void
  parcel: Parcel
  photoIndex: number
}

export const PropertyPhotosModal = ({
  open,
  onClose,
  parcel,
  photoIndex,
}: Props) => {
  /**
   * When the current photo is not the first photo, the carousel will show a flicker where it shuffles through the preceding images.
   * To circumvent this, we rotate the photo array using .slice() and .concat() so the current photo is at the start of the array.
   */
  const images = parcel.imagesWithMetadata
    .slice(photoIndex)
    .concat(parcel.imagesWithMetadata.slice(0, photoIndex))

  return (
    <Modal open={open} onClose={onClose}>
      <Box height="100%" width="100%" bgcolor="black">
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: grey[500],
            zIndex: 51,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Carousel
          animation="slide"
          autoPlay={false}
          duration={500}
          indicators={false}
          navButtonsAlwaysVisible={true}
          navButtonsProps={{
            style: {
              backgroundColor: 'rgba(200, 200, 200, 0.60)',
              padding: 4,
            },
          }}
          swipe={false}
        >
          {images.map((image, index) => (
            <Stack
              key={index}
              direction="column"
              height="100vh"
              justifyContent="center"
              width="100%"
            >
              <img
                key={index}
                src={image.signedUrl}
                alt={`Property ${index + 1}`}
                style={{objectFit: 'contain', height: '100%', width: '100%'}}
              />
            </Stack>
          ))}
        </Carousel>
      </Box>
    </Modal>
  )
}
