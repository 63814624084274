import * as React from 'react'

import {IosShare} from '@mui/icons-material'
import {
  Button,
  Typography,
  Divider,
  IconButton,
  Icon,
  CircularProgress,
  Collapse,
  SvgIcon,
  Popover,
} from '@mui/material'
import {grey} from '@mui/material/colors'
import {Box, Stack} from '@mui/system'
import {useFlags} from 'launchdarkly-react-client-sdk'
import _ from 'lodash'
import {useSnackbar} from 'notistack'

import {ParcelHighlights} from 'src/components/copilot/ParcelHighlights'
import {
  CarouselRef,
  ParcelPhotoCarousel,
} from 'src/components/copilot/ParcelPhotoCarousel'
import {SimpleTable} from 'src/components/copilot/SimpleTable'
import {WatchlistButton} from 'src/components/copilot/WatchlistButton'
import {ClaimYourSpaceModal} from 'src/components/copilot/claimYourSpace/ClaimYourSpaceModal'
import CostCalculator from 'src/components/copilot/costCalculator/CostCalculator'
import {MakeAnOfferModal} from 'src/components/copilot/makeAnOffer/MakeAnOfferModal'
import {ParcelMap} from 'src/components/copilot/mapbox/ParcelMap'
import {ParcelFeedDrawer} from 'src/components/copilot/parcelDetails/ParcelFeedDrawer'
import {PropertyPhotosModal} from 'src/components/copilot/parcelDetails/PropertyPhotosModal'
import {RentVsBuyWidget} from 'src/components/copilot/parcelDetails/RentVsBuyWidget'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {ProgressBox} from 'src/framework/ui/ProgressBox'
import {apiCoPilotParcelPath} from 'src/generated/routes'
import {useShow} from 'src/hooks/request/useShow'
import {useToggle} from 'src/hooks/util/useToggle'
import {Parcel} from 'src/types/copilot'
import {trackEvent} from 'src/util/analytics'
import {formatCurrency, formatDecimal} from 'src/util/format'
import {formatDateAsYear} from 'src/util/format/formatDate'
import {ReactComponent as WithcoCoIcon} from 'svg/withco_logo_co.svg'

type Props = {
  taxAssessorId: string
  onBack: () => void
}

export const ParcelDetails = ({onBack, taxAssessorId}: Props): JSX.Element => {
  const {theiaGenericEndpointEnabled} = useFlags()
  const [
    claimYourSpaceModalOpen,
    openClaimYourSpaceModal,
    closeClaimYourSpaceModal,
  ] = useToggle(false)
  const [makeAnOfferModalOpen, openMakeAnOfferModal, closeMakeAnOfferModal] =
    useToggle(false)
  const [
    propertyPhotoModalOpen,
    openPropertyPhotoModal,
    closePropertyPhotoModal,
  ] = useToggle(false)
  const [currentPhotoIndex, setCurrentPhotoIndex] = React.useState<number>(0)
  const [feedDrawerOpen, openFeedDrawer, closeFeedDrawer] = useToggle(false)
  const [taxHistoryExpanded, , , toggleTaxHistory] = useToggle(false)
  const carouselRef = React.useRef<CarouselRef | null>(null)

  const {enqueueSnackbar} = useSnackbar()

  const {entity: parcel, loading: isParcelFetchLoading} = useShow<Parcel>(
    apiCoPilotParcelPath(taxAssessorId),
    {
      autoRequest: true,
    },
  )

  const openPhotoFullScreen = React.useCallback(
    (photoIndex: number) => {
      setCurrentPhotoIndex(photoIndex)
      openPropertyPhotoModal()
    },
    [openPropertyPhotoModal],
  )

  const shareParcel = React.useCallback(() => {
    console.log('Parcel:', parcel)
    const baseUrl = window.location.href
    const url = new URL(baseUrl)
    url.searchParams.set('share_id', taxAssessorId)

    trackEvent('Share Property', {
      propertyId: taxAssessorId,
      address: parcel?.address,
    })
    if (navigator.share) {
      navigator
        .share({
          url: url.toString(),
          text: 'Check out this building I found on withco',
        })
        .catch(() => {
          navigator.clipboard.writeText(url.toString())
          enqueueSnackbar('Link copied to clipboard', {variant: 'success'})
        })
    } else {
      navigator.clipboard.writeText(url.toString())
      enqueueSnackbar('Link copied to clipboard', {variant: 'success'})
    }
  }, [enqueueSnackbar, parcel, taxAssessorId])
  const siteSurveyIdx = React.useMemo(() => {
    if (!parcel) {
      return undefined
    }
    const idx = parcel.imagesWithMetadata.findIndex(
      (img) => img.viewType === 'site_survey',
    )
    return idx < 0 ? undefined : idx
  }, [parcel])
  const openSiteSurvey = React.useCallback(() => {
    if (!siteSurveyIdx || !carouselRef.current) {
      return
    }
    carouselRef.current.jumpToIndex(siteSurveyIdx)
  }, [siteSurveyIdx])

  if (!parcel) {
    return <ProgressBox loading={isParcelFetchLoading} height={'100%'} />
  }

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column">
        <Box
          flex="1 0"
          overflow="auto"
          width="100%"
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Box position="relative">
            <Box
              position="absolute"
              sx={{
                left: 8,
                top: 8,
                zIndex: 10,
                backgroundColor: 'rgba(255, 255,255, .9)',
                borderRadius: '50%',
              }}
            >
              <IconButton
                color={'secondary'}
                sx={{backgroundColor: '#fff', padding: 1}}
                onClick={onBack}
              >
                <MaterialSymbolIcon sx={{fontSize: 20}}>
                  arrow_back
                </MaterialSymbolIcon>
              </IconButton>
            </Box>
            {theiaGenericEndpointEnabled && (
              <Box
                position="absolute"
                sx={{
                  right: 104,
                  top: 8,
                  zIndex: 10,
                }}
              >
                <IconButton
                  color={'secondary'}
                  sx={{
                    backgroundColor: '#fff',
                    fontSize: '20px',
                  }}
                  onClick={openFeedDrawer}
                >
                  <SvgIcon
                    inheritViewBox
                    component={WithcoCoIcon}
                    fontSize="inherit"
                  />
                </IconButton>
                <ParcelFeedDrawer
                  open={feedDrawerOpen}
                  onBack={closeFeedDrawer}
                  parcel={parcel}
                />
              </Box>
            )}
            <Box
              position="absolute"
              sx={{
                right: 56,
                top: 8,
                zIndex: 10,
                backgroundColor: 'rgba(255, 255,255, .9)',
                borderRadius: '50%',
              }}
            >
              <IconButton
                color={'secondary'}
                sx={{backgroundColor: '#fff', padding: 1}}
                onClick={shareParcel}
              >
                <IosShare sx={{fontSize: 20}} />
              </IconButton>
            </Box>
            <Box
              position="absolute"
              sx={{
                right: 8,
                top: 8,
                zIndex: 10,
              }}
            >
              <WatchlistButton parcel={parcel} />
            </Box>
            <ParcelPhotoCarousel
              ref={carouselRef}
              parcel={parcel}
              openPhotoFullScreen={openPhotoFullScreen}
              imageHeight={250}
            />
          </Box>
          <Box padding={1.5} position="relative">
            <ParcelHighlights
              parcel={parcel}
              handleViewSiteSurvey={siteSurveyIdx ? openSiteSurvey : undefined}
            />
          </Box>
          <RentVsBuyWidget
            handleClaimSpace={openClaimYourSpaceModal}
            parcel={parcel}
          />
          <Box padding={1.5}>
            <Section title="The Space">
              <Typography
                textAlign="justify"
                fontSize="0.75rem"
                paddingBottom={1}
              >
                {parcel.description}
              </Typography>
              <Divider
                sx={{borderColor: grey[300], borderBottomWidth: '0.5px'}}
              />
              <SimpleTable values={buildingValues(parcel)} />
            </Section>
          </Box>
          <Box padding={1.5}>
            <Section title="The Location">
              <Typography variant="h6" fontWeight="500" marginTop={0}>
                {parcel.neighborhood}
              </Typography>
              <Box gap={3} display={'flex'} flexDirection={'column'}>
                <ParcelMap parcel={parcel} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                  }}
                >
                  <ScoreCircle
                    score={parcel.walkScore}
                    icon={'directions_walk'}
                    label="Walk Score"
                    color="#4CAF50"
                  />
                  <ScoreCircle
                    score={parcel.transitScore}
                    icon={'directions_transit'}
                    label="Transit Score"
                    color="#2196F3"
                  />
                  <ScoreCircle
                    score={parcel.bikeScore}
                    icon={'directions_bike'}
                    label="Bike Score"
                    color="#FFC107"
                  />
                </Box>
              </Box>
            </Section>
          </Box>
          <Box padding={1.5}>
            <Section title="Price Estimates">
              <SimpleTable values={priceValues(parcel)} />
            </Section>
          </Box>
          {parcel.priceHistory?.length > 0 && (
            <Box padding={1.5}>
              <Section title="Price History">
                <SimpleTable
                  values={priceHistoryValues(parcel)}
                  headers={['Date', 'Event', 'Price']}
                />
              </Section>
            </Box>
          )}
          <Box padding={1.5}>
            <Section title="Public Tax History">
              <Stack>
                <SimpleTable
                  values={taxHistoryValues(parcel).slice(0, 4)}
                  headers={['Year', 'Property Tax', 'Assessed Value']}
                />
                <Collapse in={taxHistoryExpanded} timeout="auto" unmountOnExit>
                  <SimpleTable
                    values={taxHistoryValues(parcel).slice(5)}
                    sx={{borderTop: '0.5px solid #e0e0e0'}}
                  />
                </Collapse>
                <Stack
                  direction="row"
                  alignItems="center"
                  color="secondary"
                  onClick={toggleTaxHistory}
                  sx={{cursor: 'pointer'}}
                >
                  <MaterialSymbolIcon>
                    {taxHistoryExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}
                  </MaterialSymbolIcon>
                  <Typography fontSize="0.75rem" fontWeight="700">
                    {taxHistoryExpanded ? 'Show less' : 'Show more'}
                  </Typography>
                </Stack>
              </Stack>
            </Section>
          </Box>
          <Box padding={1.5}>
            <Section title="Cost Calculator">
              <CostCalculator
                propertyPrice={parcel.salesPrice}
                annualPropertyTaxes={parcel.taxBillAmount}
                buildingSqft={parcel.buildingSqFt || 4000}
                propertyType={parcel.propertyType || 'Retail'}
              />
            </Section>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 1,
            position: 'sticky',
            bottom: 0,
            zIndex: 50,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              trackEvent('Open Make an Offer', {
                propertyId: parcel.taxAssessorId,
                address: parcel.address,
              })
              openMakeAnOfferModal()
            }}
            sx={{
              backgroundColor: 'primary',
              color: 'white',
              padding: '10px 20px',
              width: '100%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'primary',
              },
            }}
          >
            <Typography variant="h4" component="div">
              Make an offer
            </Typography>
          </Button>
        </Box>
      </Box>
      <ClaimYourSpaceModal
        open={claimYourSpaceModalOpen}
        onClose={closeClaimYourSpaceModal}
        parcel={parcel}
      />
      <MakeAnOfferModal
        open={makeAnOfferModalOpen}
        onClose={closeMakeAnOfferModal}
      />
      <PropertyPhotosModal
        open={propertyPhotoModalOpen}
        onClose={closePropertyPhotoModal}
        parcel={parcel}
        photoIndex={currentPhotoIndex}
      />
    </>
  )
}

function ZoningPopover({
  code,
  permittedUses,
}: {
  code: string
  permittedUses: string[]
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box
      component="span"
      sx={{display: 'inline-flex', alignItems: 'center', gap: 0.5}}
    >
      {code}
      <MaterialSymbolIcon
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          fontSize: '0.75rem',
          verticalAlign: 'middle',
        }}
      >
        help_outline
      </MaterialSymbolIcon>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box fontSize="0.75rem" sx={{p: 1}}>
          {permittedUses.join(', ')}
        </Box>
      </Popover>
    </Box>
  )
}

const SQFT_FT_PER_ACRE = 43560

const buildingValues = (
  parcel: Parcel,
): [string, string | JSX.Element | null | undefined][] => [
  ['Property type', parcel.propertyType ?? '--'],
  [
    'Building Area',
    formatDecimal(parcel.buildingSqFt, {precision: 0, suffix: 'sqft'}),
  ],
  [
    'Lot Area Acres',
    formatDecimal(parcel.lotSizeAcre, {precision: 2, suffix: 'acres'}),
  ],
  [
    'Lot Area Sqft',
    formatDecimal((parcel.lotSizeAcre ?? 0) * SQFT_FT_PER_ACRE, {
      precision: 2,
      suffix: 'sqft',
    }),
  ],
  [
    'FAR',
    parcel.lotSizeAcre
      ? formatDecimal(
          (parcel.buildingSqFt ?? 0) /
            ((parcel.lotSizeAcre ?? 0) * SQFT_FT_PER_ACRE),
          {precision: 2},
        )
      : '--',
  ],
  [
    'Zoning',
    parcel.zoneCode ? (
      <ZoningPopover
        code={parcel.zoneCode.toString()}
        permittedUses={parcel.permittedUses ?? []}
      />
    ) : (
      '--'
    ),
  ],
  [
    'Current uses',
    parcel.currentUses?.length > 0 ? parcel.currentUses?.join(', ') : '--',
  ],
  [
    'Prior uses',
    parcel.priorUses?.length > 0 ? parcel.priorUses?.join(', ') : '--',
  ],
  ['Year built', parcel.yearBuilt?.toString() ?? '--'],
  ['Building material', parcel.buildingMaterial?.toString() ?? '--'],
  ['Roof condition', parcel.roofCondition?.toString() ?? '--'],
  ['HVAC type', parcel.hvacType?.toString() ?? '--'],
  ['Bathrooms', parcel.bathroomCount?.toString() ?? '--'],
  ['Windows', parcel.windows?.toString() ?? '--'],
  ['Utilities', parcel.utilities?.toString() ?? '--'],
  ['Outdoor space', parcel.outdoorSpace ? 'Yes' : 'No'],
  ['Parking spaces', parcel.parkingSpaces?.toString() ?? '--'],
]

const priceValues = (parcel: Parcel): [string, string | null | undefined][] => [
  [
    'Price',
    formatCurrency(parcel.salesPrice?.toString(), {precision: 0}) ?? '--',
  ],
  ['Price per sqft', formatCurrency(parcel.salesPricePsf?.toString()) ?? '--'],
  [
    'Rent (annual)',
    formatCurrency(parcel.rentPriceYearly.toString(), {precision: 0}) ?? '--',
  ],
  [
    'Rent (annual) per sqft',
    formatCurrency(parcel.rentPriceYearlyPsf?.toString()) ?? '--',
  ],
  [
    'Rent (monthly)',
    formatCurrency((parcel.rentPriceYearly / 12)?.toString(), {
      precision: 0,
    }) ?? '--',
  ],
]

const taxHistoryValues = (parcel: Parcel): string[][] => {
  if (!parcel.taxBillHistory) return []

  return _.chain(parcel.taxBillHistory)
    .sortBy('dueDate')
    .reverse()
    .map((taxBill) => [
      formatDateAsYear(taxBill.billDate),
      formatCurrency(taxBill.amount),
      formatCurrency(taxBill.assessedValue, {
        precision: 0,
      }),
    ])
    .value()
}

const priceHistoryValues = (parcel: Parcel): string[][] => {
  return parcel.priceHistory?.map((phEvent): string[] => [
    phEvent.date,
    phEvent.event,
    formatCurrency(phEvent.price?.toString(), {precision: 0}),
  ])
}

interface ScoreProps {
  score: number
  icon: string
  label: string
  color: string
}

const ScoreCircle: React.FC<ScoreProps> = ({score, icon, label, color}) => (
  <Box
    sx={{
      position: 'relative',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      m: 1,
    }}
  >
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'space-evenly',
      }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        size={100}
        thickness={4}
        sx={{color: (theme) => theme.palette.grey[200]}}
      />
      <CircularProgress
        variant="determinate"
        value={score}
        size={100}
        thickness={4}
        sx={{
          color: color,
          position: 'absolute',
          left: 0,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon>{icon}</Icon>
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{mt: 1}}
        >
          {label}
        </Typography>
        <Typography variant="h6" component="div">
          {score}
        </Typography>
      </Box>
    </Box>
  </Box>
)

interface SectionProps {
  title: string
  children: React.ReactNode
}
const Section = ({title, children}: SectionProps) => (
  <Stack spacing={0.5}>
    <Typography variant="h4" color="#000" gutterBottom>
      {title}
    </Typography>
    {children}
  </Stack>
)

export const ParcelDetailsDivider = (): JSX.Element => {
  return (
    <Divider
      sx={{
        mx: '-20px !important',
        borderWidth: '0.5px',
      }}
    />
  )
}
