import * as React from 'react'
import Carousel from 'react-material-ui-carousel'

import {ImageNotSupported} from '@mui/icons-material'
import {CardMedia, Box} from '@mui/material'

import {Parcel} from 'src/types/copilot'

export type CarouselRef = {
  jumpToIndex: (index: number) => void
}

type Props = {
  parcel: Parcel
  imageHeight: number
  openPhotoFullScreen?: (idx: number) => void
}

const ParcelPhotoCarousel = React.forwardRef(
  ({parcel, openPhotoFullScreen, imageHeight}: Props, ref) => {
    const [managedIndex, setManagedIndex] = React.useState<number | undefined>(
      undefined,
    )
    React.useImperativeHandle(ref, () => ({
      jumpToIndex: (index: number) => {
        setManagedIndex(undefined)
        setTimeout(() => setManagedIndex(index), 0) // Reapply the index on the next tick
      },
    }))

    return (
      <>
        {parcel.imagesWithMetadata.length > 0 ? (
          <Carousel
            animation="slide"
            autoPlay={false}
            duration={500}
            indicators={false}
            navButtonsAlwaysVisible={true}
            index={managedIndex}
            navButtonsProps={{
              style: {
                backgroundColor: 'rgba(200, 200, 200, 0.60)',
                padding: 4,
              },
            }}
          >
            {parcel.imagesWithMetadata.map((image, index) => (
              <CardMedia
                key={index}
                component="img"
                image={image.signedUrl}
                sx={{height: imageHeight, objectFit: 'cover'}}
                onClick={
                  openPhotoFullScreen
                    ? () => openPhotoFullScreen(index)
                    : undefined
                }
              />
            ))}
          </Carousel>
        ) : (
          <Box>
            <Box
              sx={{
                width: '100%',
                height: imageHeight,
                backgroundColor: 'grey.100',
                position: 'relative',
              }}
            >
              <ImageNotSupported
                fontSize="large"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                color="disabled"
              />
            </Box>
          </Box>
        )}
      </>
    )
  },
)

ParcelPhotoCarousel.displayName = 'ParcelPhotoCarousel'

export {ParcelPhotoCarousel}
