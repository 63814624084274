import * as React from 'react'

import {
  IconButton,
  Typography,
  Box,
  Divider,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import {grey} from '@mui/material/colors'

import {AdmittedUserContainer} from 'src/components/copilot/AdmittedUserContainer'
import {RangeFilterInput} from 'src/components/copilot/RangeFilterInput'
import {ToggleFilterInput} from 'src/components/copilot/ToggleFilterInput'
import {AutocompleteMultiselectTextField} from 'src/framework/form/AutocompleteMultiselectTextField'
import {AreaFormat, WholeDollarFormat} from 'src/framework/form/NumberFormat'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {MobileSwipeDrawer} from 'src/framework/ui/MobileSwipeDrawer'
import {trackEvent, trackEventDebounced} from 'src/util/analytics'

type TRange = {
  low: number
  high: number
}

// TODO: make formal withco_small and big enums
type AllowableUse = string

type Neighborhood = string

type TotalPriceRange = TRange

type MontlyPriceRange = TRange

type ListingStatus = 'for_sale' | 'for_lease' | 'off_market'

type AreaRange = TRange

type ParkingSpaceRange = TRange

type YearBuiltRange = TRange

type Vacant = true | false

type BathCountRange = TRange

type HvaccDescription = 'Central' | 'Yes' | 'Evaporative Cooler' | 'None'

export type Filter = {
  allowableUses?: AllowableUse[]
  neighborhoodList?: Neighborhood[]
  totalPriceRange?: TotalPriceRange
  monthlyPriceRange?: MontlyPriceRange
  listingStatus?: ListingStatus
  areaRange?: AreaRange
  parkingSpaceRange?: ParkingSpaceRange
  yearBuiltRange?: YearBuiltRange
  isVacant?: Vacant
  bathCountRange?: BathCountRange
  hvaccDescription?: HvaccDescription
}

type FilterKey = keyof Filter

type Props = {
  initialFilter: Filter
  applyFilter: (newFilter: Filter) => void
}

export const AdvancedMapFilters = ({
  initialFilter,
  applyFilter,
}: Props): JSX.Element => {
  const [renderDrawer, setRenderDrawer] = React.useState(false)

  const handleClick = React.useCallback((e) => {
    e.stopPropagation()
    setRenderDrawer(true)
  }, [])

  return (
    <>
      <AdmittedUserContainer
        attemptedActionName="openFilters"
        onCreateAccount={() => setRenderDrawer(true)}
      >
        <IconButton
          color="secondary"
          sx={{
            backgroundColor: '#fff',
            aspectRatio: '1 / 1',
            height: '46px',
            '&:hover': {
              backgroundColor: grey[300],
            },
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'rgba(0,0,0,0.23)', // TODO: use theme
            position: 'relative',
          }}
          onClick={handleClick}
        >
          <MaterialSymbolIcon>tune</MaterialSymbolIcon>
        </IconButton>
      </AdmittedUserContainer>
      {renderDrawer && (
        <AdvancedMapFiltersDrawer
          initialFilter={initialFilter}
          onClose={() => setRenderDrawer(false)}
          applyFilter={applyFilter}
        />
      )}
    </>
  )
}

type AdvancedMapFiltersDrawerProps = {
  initialFilter: Filter
  onClose: () => void
  applyFilter: (filter: Filter) => void
}

function didFilterChange(old: Filter, current: Filter) {
  for (const key in old) {
    if (key in current && old[key as FilterKey] !== current[key as FilterKey]) {
      return true
    }
  }
  return false
}

export const AdvancedMapFiltersDrawer = ({
  initialFilter,
  onClose,
  applyFilter,
}: AdvancedMapFiltersDrawerProps): JSX.Element => {
  const [currentFilter, setCurrentFilter] =
    React.useState<Filter>(initialFilter)

  return (
    <MobileSwipeDrawer
      open={true}
      onClose={() => {
        if (didFilterChange(initialFilter, currentFilter)) {
          trackEvent('Apply Filters')
        } else {
          trackEvent('Close Filters')
        }
        applyFilter(currentFilter)
        onClose()
      }}
      onClick={(e) => e.stopPropagation()}
      title={'Filters'}
      big={true}
    >
      <Box px={2} py={2}>
        <PropertyFilterForm
          filter={currentFilter}
          setCurrentFilter={setCurrentFilter}
        />
      </Box>
    </MobileSwipeDrawer>
  )
}

type FilterComponentProps = {
  label: string
  clearFilter: (key: keyof Filter) => void
  filterType: keyof Filter
  children: React.ReactNode
}
const FilterComponent = ({
  label,
  clearFilter,
  filterType,
  children,
}: FilterComponentProps) => {
  return (
    <Accordion
      defaultExpanded
      disableGutters
      sx={{border: 'none', '&::before': {display: 'none'}, paddingX: 0}}
    >
      <AccordionSummary
        expandIcon={<MaterialSymbolIcon>expand_more</MaterialSymbolIcon>}
        sx={{paddingX: 0}}
      >
        <Typography variant="h2">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {' '}
        <Stack direction="column" spacing={2} alignItems="flex-start">
          {children}
          <Button
            variant="text"
            sx={{
              fontWeight: 500,
              p: 0,
              textDecoration: 'underline',
              color: grey[700],
            }}
            onClick={() => {
              clearFilter(filterType)
            }}
          >
            Clear
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

type PriceType = 'totalPriceRange' | 'monthlyPriceRange'
type PriceFilterComponentProps = {
  monthly?: TRange
  total?: TRange
  onChange(type: PriceType, low: number, high: number): void
  clearFilter: (key: keyof Filter) => void
  onError: (type: PriceType, errors: string[]) => void
}
const PriceFilterComponent = ({
  total,
  monthly,
  onChange,
  clearFilter,
  onError,
}: PriceFilterComponentProps) => {
  const [type, setType] = React.useState<PriceType>(
    monthly === undefined ? 'totalPriceRange' : 'monthlyPriceRange',
  )

  const handleSliderUpdate = React.useCallback(
    (low: number | null, high: number | null, validationErrors: string[]) => {
      if (validationErrors.length > 0) {
        console.log('Error: price filter validation error')
        onError(type, validationErrors)
      }
      onChange(
        type,
        low ?? 0,
        high ?? (type === 'monthlyPriceRange' ? 50000 : 10000000),
      )
    },
    [onChange, type, onError],
  )
  return (
    <Accordion
      defaultExpanded
      disableGutters
      sx={{border: 'none', '&::before': {display: 'none'}, paddingX: 0}}
    >
      <AccordionSummary
        expandIcon={<MaterialSymbolIcon>expand_more</MaterialSymbolIcon>}
        sx={{paddingX: 0}}
      >
        <Typography variant="h2">Price</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {' '}
        <Stack direction="column" spacing={2} alignItems="flex-start">
          <>
            <ToggleButtonGroup
              exclusive
              fullWidth
              size="small"
              value={type}
              onChange={(e, priceDataValue) => {
                setType(priceDataValue)
              }}
            >
              <ToggleButton value="totalPriceRange">
                <Typography>Est. Property Value</Typography>
              </ToggleButton>
              <ToggleButton value="monthlyPriceRange">
                <Typography>Monthly Payment</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
            {type === 'totalPriceRange' ? (
              <RangeFilterInput
                key={type}
                low={total?.low ?? null}
                high={total?.high ?? null}
                min={0}
                max={10000000}
                sliderIncrement={10000}
                onChange={handleSliderUpdate}
                inputComponent={WholeDollarFormat}
              />
            ) : (
              <RangeFilterInput
                key={type}
                low={monthly?.low ?? null}
                high={monthly?.high ?? null}
                min={0}
                max={50000}
                sliderIncrement={10}
                onChange={handleSliderUpdate}
                inputComponent={WholeDollarFormat}
              />
            )}
          </>
          <Button
            variant="text"
            sx={{
              fontWeight: 500,
              p: 0,
              textDecoration: 'underline',
              color: grey[700],
            }}
            onClick={() => {
              clearFilter(type)
            }}
          >
            Clear
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

type FormErrors = Partial<Record<keyof Filter, string[]>>

type PropertyFilterFormProps = {
  filter: Filter
  setCurrentFilter: (filter: Filter) => void
}

const PropertyFilterForm: React.FC<PropertyFilterFormProps> = ({
  filter,
  setCurrentFilter,
}) => {
  const [errors, setErrors] = React.useState<FormErrors>({})
  const onError = (key: keyof Filter, newErrors: string[]) => {
    setErrors({
      ...errors,
      [key]: newErrors,
    })
  }

  const clearError = (key: keyof Filter) => {
    setErrors({
      ...errors,
      [key]: undefined,
    })
  }

  const updateFilter = <K extends keyof Filter>(key: K, value: Filter[K]) => {
    trackEventDebounced('Set Filter', {filter: key, filterValue: value})
    clearError(key)
    setCurrentFilter({...filter, [key]: value})
  }

  const clearFilter = (key: keyof Filter) => {
    trackEvent('Clear Filter', {filter: key})
    clearError(key)
    setCurrentFilter({...filter, [key]: undefined})
  }

  return (
    <Paper
      sx={{width: '100%', maxWidth: 600, mx: 'auto', p: 2, border: 'none'}}
    >
      <FilterComponent
        label="Listing Status"
        filterType="listingStatus"
        clearFilter={clearFilter}
      >
        <ToggleFilterInput<ListingStatus>
          value={filter.listingStatus ?? null}
          onChange={(value) => updateFilter('listingStatus', value)}
          options={[
            {value: 'for_sale', label: 'For Sale'},
            {value: 'for_lease', label: 'For Lease'},
            {value: 'off_market', label: 'Off Market'},
          ]}
        />
      </FilterComponent>
      <Divider />

      <PriceFilterComponent
        total={filter.totalPriceRange}
        monthly={filter.monthlyPriceRange}
        clearFilter={clearFilter}
        onChange={(type, low, high) => {
          updateFilter(type, {low, high})
        }}
        onError={onError}
      />
      <Divider />

      <FilterComponent
        label="Building Area"
        filterType="areaRange"
        clearFilter={clearFilter}
      >
        <RangeFilterInput
          low={filter.areaRange?.low ?? null}
          high={filter.areaRange?.high ?? null}
          min={0}
          max={10000}
          sliderIncrement={100}
          onChange={(low, high) => {
            updateFilter('areaRange', {
              low: low ?? 0,
              high: high ?? 10000,
            })
          }}
          inputComponent={AreaFormat}
        />
      </FilterComponent>
      <Divider />

      <FilterComponent
        label="Allowable Uses"
        filterType="allowableUses"
        clearFilter={clearFilter}
      >
        <AutocompleteMultiselectTextField
          id="allowableUses"
          label=""
          sx={{width: '100%'}}
          values={
            filter.allowableUses?.map((pc) => ({value: pc, label: pc})) ?? []
          }
          onChange={(value) =>
            updateFilter(
              'allowableUses',
              value?.map((vl) => vl.value),
            )
          }
          options={[
            {value: 'agriculture', label: 'Agriculture'},
            {value: 'public sector', label: 'Public Sector'},
            {value: 'personal care', label: 'Personal Care'},
            {value: 'professional services', label: 'Professional Services'},
            {value: 'fitness & recreation', label: 'Fitness & Recreation'},
            {value: 'automotive', label: 'Automotive'},
            {value: 'food & beverage', label: 'Food & Beverage'},
            {value: 'retail', label: 'Retail'},
            {value: 'accommodation', label: 'Accommodation'},
            {value: 'medical', label: 'Medical'},
            {value: 'light industrial', label: 'Light Industrial'},
            {value: 'grocery & convenience', label: 'Grocery & Convenience'},
            {value: 'child care', label: 'Child Care'},
            {value: 'heavy industrial', label: 'Heavy Industrial'},
            {value: 'education', label: 'Education'},
            {
              value: 'building & home services',
              label: 'Building & Home Services',
            },
            {value: 'local services', label: 'Local Services'},
          ]}
        />
      </FilterComponent>
      <Divider />

      <FilterComponent
        label="Vacancy"
        filterType="isVacant"
        clearFilter={clearFilter}
      >
        <ToggleFilterInput
          value={
            filter.isVacant === undefined
              ? null
              : filter.isVacant
              ? 'Vacant'
              : 'Occupied'
          }
          onChange={(value) => updateFilter('isVacant', value === 'Vacant')}
          options={[
            {value: 'Vacant', label: 'Vacant'},
            {value: 'Occupied', label: 'Occupied'},
          ]}
        />
      </FilterComponent>
      <Divider />

      <FilterComponent
        label="Year Built"
        filterType="areaRange"
        clearFilter={clearFilter}
      >
        <RangeFilterInput
          low={filter.yearBuiltRange?.low ?? null}
          high={filter.yearBuiltRange?.high ?? null}
          min={1900}
          max={new Date().getFullYear()}
          sliderIncrement={5}
          onChange={(low, high) =>
            updateFilter('yearBuiltRange', {
              low: low ?? 1900,
              high: high ?? new Date().getFullYear(),
            })
          }
        />
      </FilterComponent>
      <Divider />

      <FilterComponent
        label="Neighborhood"
        filterType="neighborhoodList"
        clearFilter={clearFilter}
      >
        <AutocompleteMultiselectTextField
          id="neighborhoodList"
          label=""
          sx={{width: '100%'}}
          values={
            filter.neighborhoodList?.map((pc) => ({value: pc, label: pc})) ?? []
          }
          onChange={(value) =>
            updateFilter(
              'neighborhoodList',
              value?.map((vl) => vl.value),
            )
          }
          options={[
            {value: 'West', label: 'West'},
            {value: 'Five Points', label: 'Five Points'},
            {value: 'Wade', label: 'Wade'},
            {value: 'Southwest', label: 'Southwest'},
            {value: 'Falls Of Neuse', label: 'Falls Of Neuse'},
            {value: 'East Raleigh', label: 'East Raleigh'},
            {value: 'Northwest', label: 'Northwest'},
            {value: 'Six Forks', label: 'Six Forks'},
            {value: 'Mordecai', label: 'Mordecai'},
            {value: 'North Central', label: 'North Central'},
            {value: 'Southeast', label: 'Southeast'},
            {value: 'North', label: 'North'},
            {value: 'South', label: 'South'},
            {value: 'Hillsborough', label: 'Hillsborough'},
            {value: 'Central', label: 'Central'},
            {value: 'Northeast', label: 'Northeast'},
            {value: 'Glenwood', label: 'Glenwood'},
            {value: 'South Central', label: 'South Central'},
          ]}
        />
      </FilterComponent>
    </Paper>
  )
}
