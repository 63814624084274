import * as React from 'react'

import {Box, CircularProgress, Stack, Paper, Typography} from '@mui/material'

import {ParcelCard} from 'src/components/copilot/ParcelCard'
import {ParcelFeed} from 'src/components/copilot/parcelDetails/ParcelFeedDrawer'
import {useWatchlistParcels} from 'src/hooks/request/useWatchlistParcels'

export const CopilotFeedScreen = () => {
  const {watchlistParcels, hasBeenFetched: watchlistHasBeenFetched} =
    useWatchlistParcels()

  if (!watchlistHasBeenFetched) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 50,
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Paper sx={{height: '100%', width: '100%', p: 2}}>
      <Stack spacing={1}>
        <Typography variant="h4" mb={2}>
          Targets
        </Typography>
        {watchlistParcels &&
          watchlistParcels.map((parcel) => (
            <ParcelCard
              key={`parcel-card${parcel.taxAssessorId}`}
              parcel={parcel}
              DrawerContent={ParcelFeed}
            />
          ))}
      </Stack>
    </Paper>
  )
}
