import * as React from 'react'

import {useMutex} from 'src/contexts/mutex/useMutex'
import {apiCoPilotParcelsPath} from 'src/generated/routes'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {useIndex} from 'src/hooks/request/useIndex'
import {useAppDispatch, useAppSelector} from 'src/store/partners/store'
import {
  setWatchlistParcels,
  removeWatchlistParcels,
} from 'src/store/partners/watchlistParcelsSlice'
import {Parcel} from 'src/types/copilot'
import {ParsedIndexResponse} from 'src/util/request/parseResponse'

export const useWatchlistParcels = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((state) => state.watchlistParcels)
  const {getLock} = useMutex()

  const {user, hasBeenFetched: userHasBeenFetched} = useCopilotAccount()

  const requestOptions = React.useMemo(
    () => ({
      suppressedErrorCodes: [401],
      onSuccess: ({
        entities: watchlistParcels,
      }: ParsedIndexResponse<Parcel>) => {
        dispatch(setWatchlistParcels(watchlistParcels))
      },
      onError: () => {
        dispatch(removeWatchlistParcels())
      },
    }),
    [dispatch],
  )

  const {fetch, error: fetchWatchlistParcelsError} = useIndex<Parcel>(
    apiCoPilotParcelsPath(),
    requestOptions,
  )

  const fetchWatchlistParcels = React.useCallback(() => {
    return fetch({params: {watchlist: true}})
  }, [fetch])

  React.useEffect(() => {
    if (state.items.length > 0) return
    if (!user || !userHasBeenFetched) return
    if (!getLock('copilotWatchlistParcels')) return

    fetchWatchlistParcels()
  }, [
    fetchWatchlistParcelsError,
    state.items,
    dispatch,
    getLock,
    fetchWatchlistParcels,
    user,
    userHasBeenFetched,
  ])

  const isWatchlistParcel = React.useCallback(
    (parcel: Parcel) => {
      return state.items.some((watchlistParcel) => {
        return watchlistParcel.id === parcel.id
      })
    },
    [state.items],
  )

  return {
    watchlistParcels: state.items,
    hasBeenFetched: state.hasBeenFetched,
    isWatchlistParcel,
    refetch: fetchWatchlistParcels,
  }
}
