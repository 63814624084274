import * as React from 'react'

import {
  Timeline,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from '@mui/lab'
import _ from 'lodash'

export type FeedEvent = {
  date: string
  description: string
}

export type Feed = {
  present: string
  historicalEvents: FeedEvent[]
}

type Props = {
  feed: Feed
}

export function FeedTimeline({feed}: Props) {
  const sortedHistoricalEvents = React.useMemo(
    () =>
      _.chain(feed.historicalEvents)
        .sortBy((e) => e.date.slice(0, 5))
        .reverse()
        .value(),
    [feed],
  )
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.1,
        },
      }}
    >
      {sortedHistoricalEvents.map((event, idx) => (
        <TimelineItem key={idx}>
          <TimelineOppositeContent
            color="textSecondary"
            sx={{marginY: '3.5px'}}
          >
            {event.date.slice(0, 4)}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            {idx !== sortedHistoricalEvents.length - 1 ? (
              <TimelineConnector />
            ) : null}
          </TimelineSeparator>
          <TimelineContent sx={{marginY: '3.5px'}}>
            {event.description}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
