import * as React from 'react'

import {IconButton, SvgIcon} from '@mui/material'

import {AdmittedUserContainer} from 'src/components/copilot/AdmittedUserContainer'
import {
  apiCoPilotDestroyWatchlistParcelPath,
  apiCoPilotParcelsWatchlistPath,
} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {useWatchlistParcels} from 'src/hooks/request/useWatchlistParcels'
import {Parcel} from 'src/types/copilot'
import {trackEvent} from 'src/util/analytics'
import {ReactComponent as TargetIcon} from 'svg/target.svg'

interface Props {
  parcel: Parcel
}

export const WatchlistButton = ({parcel}: Props): JSX.Element => {
  const {request: saveToWatchlist, loading: saveInProgress} = useRequest<
    void,
    {taxAssessorId: string}
  >('POST', apiCoPilotParcelsWatchlistPath())

  const {request: removeFromWatchlist, loading: removeInProgress} =
    useRequest('DELETE')

  const [requestInProgress, setRequestInProgress] = React.useState<
    'add' | 'remove' | null
  >(null)

  const {isWatchlistParcel, refetch: fetchWatchlist} = useWatchlistParcels()

  const watchlistStatus = React.useMemo((): 'on' | 'off' => {
    if (requestInProgress === 'add') return 'on'
    if (requestInProgress === 'remove') return 'off'

    return isWatchlistParcel(parcel) ? 'on' : 'off'
  }, [requestInProgress, isWatchlistParcel, parcel])

  const handleAddToWatchlist = () => {
    trackEvent('Add Property to Watchlist', {
      propertyId: parcel.taxAssessorId,
      address: parcel.address,
    })

    setRequestInProgress('add')
    saveToWatchlist({data: {taxAssessorId: parcel.taxAssessorId}})
      .then(fetchWatchlist)
      .finally(() => {
        setRequestInProgress(null)
      })
  }

  const handleRemoveFromWatchlist = () => {
    trackEvent('Remove Property from Watchlist', {
      propertyId: parcel.taxAssessorId,
      address: parcel.address,
    })

    setRequestInProgress('remove')
    removeFromWatchlist({
      url: apiCoPilotDestroyWatchlistParcelPath(parcel.taxAssessorId),
    })
      .then(fetchWatchlist)
      .finally(() => {
        setRequestInProgress(null)
      })
  }

  return (
    <AdmittedUserContainer
      attemptedActionName="addPropertyToWatchlist"
      onCreateAccount={handleAddToWatchlist}
    >
      <IconButton
        color={watchlistStatus === 'on' ? 'primary' : 'secondary'}
        sx={{
          backgroundColor: '#ffffff',
          padding: '6px',
          '&:hover': {
            backgroundColor: '#ffffff',
          },
        }}
        onClick={(e) => {
          e.stopPropagation()
          if (!saveInProgress && !removeInProgress) {
            watchlistStatus === 'on'
              ? handleRemoveFromWatchlist()
              : handleAddToWatchlist()
          }
        }}
      >
        <SvgIcon
          inheritViewBox
          component={TargetIcon}
          sx={{fontSize: '24px'}}
        />
      </IconButton>
    </AdmittedUserContainer>
  )
}
